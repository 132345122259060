<template>
  <div v-if="!is_register">
    <!-- <div class="d-flex" style="margin-bottom: 20px"> -->
    <!-- 返回 -->
    <el-row>
      <el-col :xs="24" :sm="6">
        <el-button
          icon="el-icon-arrow-left"
          class="mr-3 mb-2"
          style="color: #50b5ff; height: 41px"
          @click="$router.back()"
        >
          {{ $t("Events.024@back") }}</el-button
        >
      </el-col>
      <el-col :xs="24" :sm="18">
        <el-alert
          style="color: #ffba68"
          show-icon
          class="mb-2"
          center
          :title="$t('Events.be4@you_have_not_registe')"
          type="warning"
        >
        </el-alert>
      </el-col>
    </el-row>
    <!-- </div> -->
    <el-card>
      <div style="background:#fff;">
        <img
          src="@/assets/images/group/empty1.png"
          alt=""
          style="width:250px;margin: 100px auto; display: block"
        />
      </div>
      
    </el-card>
  </div>
  <section v-else>
    <el-row>
      <el-col :xs="24" :sm="18">
        <div class="program container690">
          <el-button
            icon="el-icon-arrow-left"
            style="color: #50b5ff; height: 41px; margin-bottom: 20px"
            @click="$router.back()"
          >
            {{ $t("Events.024@back") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="18">
        <div class="program container690" v-if="!Array.isArray(allProgram)">
          <el-card>
            <div class="program-header d-flex justify-content-between">
              <div class="program-btn">
                <el-button
                  :type="index ? 'primary' : 'info'"
                  plain
                  :disabled="!index"
                  style="border: none"
                  @click="changeIndex(0)"
                >
                  {{ $t("Events.99e@previous") }}</el-button
                >
              </div>
              <div class="d-flex">
                <i class="ri-calendar-2-line"></i>
                <p class="m-0">
                  {{ Object.keys(this.allProgram)[index] }}
                  <span style="color: #b1b6ba"
                    >({{
                      allProgram[Object.keys(allProgram)[index]] &&
                      allProgram[Object.keys(allProgram)[index]][0].time_zone_2
                    }})</span
                  >
                </p>
              </div>
              <div class="program-btn">
                <el-button
                  :type="
                    index != Object.keys(this.allProgram).length - 1
                      ? 'primary'
                      : 'info'
                  "
                  plain
                  :disabled="index == Object.keys(this.allProgram).length - 1"
                  style="border: none; width: 90px"
                  @click="changeIndex(1)"
                  >{{ $t("Events.b72@next") }}
                </el-button>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 20px">
            <section
              v-for="(v, i) in allProgram[Object.keys(this.allProgram)[index]]"
              :key="i"
              class="program-content"
            >
              <p class="m-0">
                {{ v.time1 }}
                <span style="color: #b1b6ba">({{ v.time_zone_2 }})</span>
              </p>
              <ul class="p-0 program-list">
                <li>
                  <div>
                    <h3 style="cursor: pointer" @click="toProgramDetail(v)">
                      {{ v.program_title }}
                      <span
                        :class="{
                          MeetingTag: v.model_type == 4,
                          EventTag: v.model_type == 2,
                          courseTag: v.model_type == 3,
                          projectTag: v.model_type == 1,
                          noneTag: v.model_type == 0,
                        }"
                        class="tag"
                        v-if="v.is_cron"
                      >
                        {{ judgeCurrentType(v) }}
                      </span>
                      <span class="ml-2"
                        >({{ v.program_count }}/{{ v.max_attendee }})</span
                      >
                    </h3>
                    <span
                      ><i class="ri-time-line"></i>{{ v.time }}
                      <span style="color: #b1b6ba">({{ v.time_zone }})</span>
                    </span>
                    <section v-if="!(v.model_type == 3 && v.package_type == 1)">
                      <span v-if="v.type == 3">
                        <i class="ri-map-pin-line"></i>{{ v.venue
                        }}{{ v.room }} / Online
                      </span>
                      <span v-else-if="v.type != 2 && v.room"
                        ><i class="ri-map-pin-line"></i>{{ v.venue
                        }}{{ v.room }}</span
                      >
                      <span v-else-if="v.type_desc"
                        ><i class="ri-map-pin-line"></i>{{ v.type_desc }}</span
                      >
                    </section>
                    <span
                      ><i class="ri-lock-unlock-line"></i
                      >{{ Object.values(v.attendee_tags).join("，") }}
                    </span>
                    <span v-if="v.special_guest.length"
                      ><i class="ri-user-3-line"></i>
                      {{ v.special_guest.map((n) => n.name).join("，") }}
                    </span>
                  </div>
                  <div v-if="!v.my_schedule" class="i-tooltips">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Add to my Schedule"
                      placement="left"
                    >
                      <i
                        @click="addToMyChedule(v)"
                        class="ri-add-circle-line"
                      ></i>

                      <div style="cursor: pointer" slot="content">
                        <span class="addSchduleTitle mb-5"
                          >Add to my Schedule</span
                        >
                        <p class="addSchduleTime m-0">
                          Before {{ v.end_time }}
                        </p>
                      </div>
                    </el-tooltip>
                  </div>

                  <div v-else class="i-remove">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Remove"
                      placement="left"
                    >
                      <i
                        @click="delMySchedule(v)"
                        class="ri-indeterminate-circle-line"
                      ></i>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </section>
          </el-card>
        </div>
        <div v-else style="background:#fff;" class="container690">
          <el-card>
            <img
              src="@/assets/images/group/empty1.png"
              alt=""
              style="width:250px; margin: 100px auto; display: block"
            />
          </el-card>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" class="RightSidebar" v-if="single_schedule.length">
        <iq-card class="cardStyle">
          <div class="mb-2 title">
            <i class="ri-calendar-2-line mr-2"></i>
            {{ $t("Events.31b@my_schedule") }}
          </div>
          <div>
            <ul class="p-0 eventList">
              <li
                class="event"
                v-for="(v, index) in single_schedule"
                :key="index"
              >
                <div
                  style="cursor: pointer"
                  class="title"
                  @click="toProgramDetail(v)"
                >
                  {{ v.program_title }}
                </div>
                <div class="dateTime">{{ v.time }}</div>
                <div class="dateTime">{{ v.time_zone }}</div>
                <hr />
              </li>
            </ul>
            <b-button
              variant="primary"
              class="w-100"
              @click="
                $router.push({
                  path: '/MySchedule',
                  query: { id: $route.query.id, type: $route.query.type },
                })
              "
              >{{ $t("Events.bbe@view_all") }}</b-button
            >
          </div>
          <!-- <div v-if="false" class="nodata">
            <img src="@/assets/images/event/nodata.png" alt="" />
          </div> -->
        </iq-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {};
  },

  components: {},
  filters: {
    jumpModelFilter(val) {
      // console.log("cal", val);
      return val == 1
        ? vm.$t("Events.135@events")
        : val == 2
        ? "Event"
        : val == 3
        ? "Course"
        : val == 4
        ? "Meeting"
        : "";
    },
  },
  computed: {
    ...mapState({
      is_register: (state) => {
        return state.Process.is_register;
      },
      allProgram: (state) => {
        return state.Process.all_program;
      },
      index: (state) => {
        if (state.Process.tabIndex >= 1) {
          return state.Process.tabIndex;
        } else {
          return 0;
        }
      },
      single_schedule: (state) => {
        return state.Process.single_schedule;
      },
      typeId: (state) => state.Process.typeId,
    }),
  },
  created() {
    this.getProgramList(2);
    this.getProgramList(4);
  },

  methods: {
    ...mapMutations(["changeIndex"]),
    ...mapActions(["projectProgram", "projectAddProgram"]),
    judgeCurrentType(item) {
      let type = item.model_type;
      return type == 1
        ? "Project"
        : type == 2
        ? "Event"
        : type == 3
        ? "Course"
        : type == 4
        ? "Meeting"
        : "";
    },
    toProgramDetail(item) {
      let path = {
        1: "/project-detail",
        2: "/event-detail",
        3: "/course-detail",
        4: "/meeting-details",
      };
      if (item.is_cron) {
        this.$router.push({
          path: path[item.model_type],
          query: {
            id: item.model_id,
            type: item.model_type,
          },
        });
      }
    },
    addToMyChedule(v) {
      let type = this.$route.query.type;
      let id = this.$route.query.id;
      this.projectAddProgram([{ v, id }, type]);
    },

    // 删除个人行程
    async delMySchedule(item) {
      // console.log('item', item);
      let type = this.$route.query.type;
      let ajaxType = {
        1: "projectDeleteProgram",
        2: "eventDeleteProgram",
        3: "courseDeleteProgram",
        4: "meetingDeleteProgram",
      };
      let res = await this.$http[ajaxType[type]]({
        my_schedule_id: item.my_schedule_id,
        my_schedule_model_type: item.my_schedule_model_type,
      });
      if (res.status == 200) {
        this.getProgramList(2);
        this.getProgramList(4);
      }
    },

    // 调用列表
    async getProgramList(type) {
      let routerType = this.$route.query.type;
      let ajaxType = {
        1: "projectProgram",
        2: "eventProgram",
        3: "courseProgram",
        4: "meetingProgram",
      };
      await this.projectProgram([
        {
          [this.typeId[routerType]]: this.$route.query.id,
          type: type,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        ajaxType[routerType],
      ]);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .program-header {
    display: flex;
    align-items: center;

    > div i {
      font-size: 16px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #666;
      font-size: 16px;
    }

    > div.program-btn {
      /* display: flex; */
      /* flex-grow: 1; */
      /* justify-content: flex-end; */

      span {
        display: block;
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 10px;
        padding-left: 64px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 14px;
          left: 26px;
        }

        > div {
          display: flex;
          flex-direction: column;

          > h3 {
            font-size: 16px;
            color: #333;

            > .tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }
            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }
            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 8px 0;
            line-height: 1;

            > i {
              font-size: 17px;
              padding-right: 10px;
            }
          }
        }

        > div.i-tooltips {
          i {
            font-size: 22px;
            color: #50b5ff;
            cursor: pointer;
          }
        }
        > div.i-remove {
          i {
            font-size: 22px;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
    }
  }

  .RightSidebar {
    .nodata {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }

    .cardStyle {
      padding: 20px 15px;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000000;

        i {
          font-size: 18px;
          color: #4fb6ff;
        }
      }

      .eventList {
        .event {
          .title {
            color: #333333;
          }

          .dateTime {
            font-size: 12px;
            color: #666666;
          }
        }
      }
    }
  }

  // 添加图标的样式

  .addSchduleTitle {
    color: #333;
  }

  .addSchduleTime {
    color: #ff9b8a;
    padding-top: 5px;
  }
}
@media (max-width: 576px) {
  .program-header {
    display: flex;
    align-items: center;

    > div i {
      font-size: 8px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #666;
      font-size: 8px;
    }

    > div.program-btn {
      /* display: flex; */
      /* flex-grow: 1; */
      /* justify-content: flex-end; */

      span {
        display: block;
        width: 50px;
        height: 30px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 8px;
        padding-left: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 8px;
          left: 13px;
        }

        > div {
          display: flex;
          flex-direction: column;

          > h3 {
            font-size: 8px;
            color: #333;

            > .tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }
            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }
            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 8px 0;
            line-height: 1;

            > i {
              font-size: 10px;
              padding-right: 8px;
            }
          }
        }

        > div.i-tooltips {
          i {
            font-size: 12px;
            color: #50b5ff;
            cursor: pointer;
          }
        }
        > div.i-remove {
          i {
            font-size: 12px;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
    }
  }

  .RightSidebar {
    .nodata {
      display: flex;
      justify-content: center;
      margin: 30px 0;
    }

    .cardStyle {
      padding: 10px 8px;

      .title {
        font-size: 10px;
        font-weight: 500;
        color: #000000;

        i {
          font-size: 10px;
          color: #4fb6ff;
        }
      }

      .eventList {
        .event {
          .title {
            color: #333333;
          }

          .dateTime {
            font-size: 8px;
            color: #666666;
          }
        }
      }
    }
  }

  // 添加图标的样式

  .addSchduleTitle {
    color: #333;
  }

  .addSchduleTime {
    color: #ff9b8a;
    padding-top: 5px;
  }
}
</style>

<style>
::v-deep .el-tooltip__popper .is-light {
  border: 1px solid #ddd !important;
}
</style>
